<template>
  <div class="userpage">
    <v-toolbar flat dense>
      <v-toolbar-title>My certifications</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" x-small text @click.stop="newDialog = true">
        new certification
      </v-btn>
      <CertNew
        :newDialog="newDialog"
        @closeNew="closeNew"
        @submitNew="submitNew"
      />
      <v-btn icon @click="refresh()">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card flat>
      <v-card-text>
        <v-data-iterator :items="certList">
          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="item in props.items"
                :key="item.id"
                cols="12"
                sm="4"
                md="4"
                lg="4"
              >
                <v-card flat outlined>
                  <v-img height="250" :src="pix(item.file_name)"></v-img>
                  <v-card-text class="card_list">
                    <ul>
                      <li>
                        <div class="myleft">name</div>
                        <div class="myright">
                          {{ truncateString(item.name, 22) }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">description</div>
                        <div class="myright">
                          {{ truncateString(item.description, 20) }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">reg. #</div>
                        <div class="myright">
                          {{ truncateString(item.registration_number, 20) }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">active</div>
                        <div class="myright">
                          {{ item.active }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">aquired</div>
                        <div class="myright">
                          {{ item.aquired_since | myDate }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                    </ul>
                  </v-card-text>
                  <v-card-actions style="padding: 8px 16px">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          text
                          x-small
                          color="primary"
                          @click.stop="$set(pixDialog, item.id, true)"
                        >
                          Upload
                        </v-btn>
                      </template>
                      <span>Upload an image of the certification/award</span>
                    </v-tooltip>
                    <CertFile
                      v-if="pixDialog[item.id]"
                      :pixDialog="pixDialog[item.id]"
                      :myId="item.id"
                      :name="item.name"
                      @closePix="closePix"
                      @submitPix="submitPix"
                    />

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          x-small
                          v-on="on"
                          text
                          color="indigo"
                          :disabled="prevDisable(item.file_name)"
                          @click.stop="$set(previewDialog, item.id, true)"
                        >
                          preview
                        </v-btn>
                      </template>
                      <span>Preview certification/award</span>
                    </v-tooltip>
                    <CertPreview
                      v-if="previewDialog[item.id]"
                      :previewDialog="previewDialog[item.id]"
                      :myId="item.id"
                      @closePreview="closePreview"
                    />
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          color="primary"
                          @click.stop="$set(viewDialog, item.id, true)"
                        >
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>View Detail</span>
                    </v-tooltip>
                    <CertView
                      v-if="viewDialog[item.id]"
                      :viewDialog="viewDialog[item.id]"
                      :myId="item.id"
                      :name="item.name"
                      @closeView="closeView"
                    />

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          color="primary"
                          @click.stop="$set(editDialog, item.id, true)"
                        >
                          <v-icon>mdi-circle-edit-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Update</span>
                    </v-tooltip>
                    <CertEdit
                      v-if="editDialog[item.id]"
                      :editDialog="editDialog[item.id]"
                      :myId="item.id"
                      :name="item.name"
                      @closeEdit="closeEdit"
                      @submitEdit="submitEdit"
                    />

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="error"
                          icon
                          @click.stop="$set(removeDialog, item.id, true)"
                        >
                          <v-icon>mdi-delete-empty-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                    <CertRemove
                      v-if="removeDialog[item.id]"
                      :removeDialog="removeDialog[item.id]"
                      :myId="item.id"
                      :name="item.name"
                      @closeRemove="closeRemove"
                      @submitRemove="submitRemove"
                    />
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-card-text>
    </v-card>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "../../../mixins/utils.js";
import CertNew from "../../../components/accounts/certif/newone.vue";
import CertFile from "../../../components/accounts/certif/picture.vue";
import CertView from "../../../components/accounts/certif/view.vue";
import CertEdit from "../../../components/accounts/certif/update.vue";
import CertPreview from "../../../components/accounts/certif/preview.vue";
import CertRemove from "../../../components/accounts/certif/remove.vue";

export default {
  components: {
    CertNew,
    CertFile,
    CertView,
    CertEdit,
    CertPreview,
    CertRemove,
  },
  mixins: [Utils],
  data: () => ({
    newDialog: false,
    viewDialog: {},
    editDialog: {},
    removeDialog: {},
    previewDialog: {},
    pixDialog: {},
    CertPreview: {},
    loading: false,
    certList: [],
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
  }),
  created() {
    this.list();
  },
  methods: {
    prevDisable(pix) {
      if (pix === "") {
        return true;
      } else {
        return false;
      }
    },
    pix(img) {
      if (img === "") {
        return "";
      } else {
        return img;
      }
    },
    closePreview(p) {
      this.$set(this.previewDialog, p.id, p.state);
    },
    refresh() {
      this.list();
    },
    list() {
      this.loading = true;
      let self = this;
      Restful.subscribers.certificate
        .list()
        .then((response) => {
          console.log("response:", response);
          if (response.status === 200) {
            this.certList = response.data;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    closeNew(p) {
      this.newDialog = p.state;
    },
    submitNew(p) {
      this.snack.bar = true;
      let self = this;
      Restful.subscribers.certificate
        .create(p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });

      this.newDialog = p.state;
    },
    closeView(p) {
      this.$set(this.viewDialog, p.id, p.state);
    },
    closeEdit(p) {
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      this.snack.bar = true;
      // console.log(p);
      let self = this;
      Restful.subscribers.certificate
        .update(p.id, p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.editDialog, p.id, p.state);
    },
    closeRemove(p) {
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitRemove(p) {
      this.snack.bar = true;
      // console.log(p);
      let self = this;
      Restful.subscribers.certificate
        .remove(p.id)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.removeDialog, p.id, p.state);
    },
    closePix(p) {
      this.$set(this.pixDialog, p.id, p.state);
    },
    submitPix(p) {
      this.snack.bar = true;
      // console.log("id:", p.id);
      // console.log("data:", p.data);

      let self = this;
      Restful.subscribers.certificate
        .upload(p.id, p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.pixDialog, p.id, p.state);
    },
  },
};
</script>
